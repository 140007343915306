import React, { useState } from "react";

const SubscriptionForm = () => {
  const [status, setStatus] = useState(null);
  const [email, setEmail] = useState("");

  const FORM_URL = `https://app.convertkit.com/forms/1907071/subscriptions`;

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = new FormData(e.target);

    try {
      const response = await fetch(FORM_URL, {
        method: "post",
        body: data,
        headers: {
          accept: "application/json",
        },
      });
      setEmail("");
      const json = await response.json();
      if (json.status === "success") {
        setStatus("SUCCESS");
        return;
      }
    } catch (err) {
      setStatus("ERROR");
      console.log(err);
    }
  };

  const handleInputChange = (event) => {
    const { value } = event.target;
    setEmail(value);
  };

  return (
    <div className="border border-gray-400 border-solid rounded-lg p-8 my-8">
      <h2 className="my-0">Join My Newsletter</h2>
      <p className="my-2">
        If you liked this article, subscribe to receive more to your inbox.
      </p>

      {status === "SUCCESS" && <p>Please go confirm your subscription!</p>}
      {status === "ERROR" && <p>Oops, Something went wrong! try again.</p>}

      <form
        className="sub__form"
        action={FORM_URL}
        method="post"
        onSubmit={handleSubmit}
      >
        <input
          type="email"
          aria-label="Your email"
          name="email_address"
          placeholder="Your email address"
          onChange={handleInputChange}
          className="px-4 text-base font-sans w-6/12 border border-gray-300 rounded-md py-2"
          value={email}
          required
        />

        <button
          className="text-white mx-2 bg-yellow-400 rounded-md border-none font-sans py-2 text-base px-5"
          type="submit"
        >
          Subscribe
        </button>
      </form>

      <p className="my-2">No spam. You can unsubscribe at any time.</p>
    </div>
  );
};

export default SubscriptionForm;
